import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
	providedIn: 'root'
})
export class InterceptorAuthService implements HttpInterceptor {
	// actualToken = window.atob(this.tokenValue);


	constructor() { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const md5 = new Md5();
		// console.log('auth test ng', md5.appendStr('hello').end());
		const currentTimestaps = new Date().getTime().toString();
		const authstr = md5.appendStr('user=covetusDev&pass=70bcceab4041ad1843196f17f67d65b9&token=' + currentTimestaps).end();
		// console.log(authstr.toString());
		request = request.clone({
			setHeaders: {
				// 'Content-Type': 'application/json',
				'Authorization': authstr.toString(),
				'Authtimestamp': currentTimestaps
			}
		});
		return next.handle(request);
	}

}
