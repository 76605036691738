import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from './services/user.service'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare let $: any;
declare let jQuery: any;
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	messages: any[] = [];
	subscription: Subscription;
	//
	getHeader: boolean = false
	constructor(private UserService: UserService, private router:Router, public title:Title, public meta:Meta) {
		
		
	}
	changeOfRoutes(event) {
		window.scroll(0, 0);
		
		if(this.router.url.includes('contact-us')){
			this.title.setTitle('Contact Us | Cosmos Quartz')
		}else if(this.router.url.includes('location')){
			this.title.setTitle('Explore and Browse our Products at Different Locations in the US | Cosmos Quartz')
			
		}else if(this.router.url.includes('/customer-service')){
			this.title.setTitle('Care and Warranty of Quartz Products | Cosmos Quartz')
		}
		else if(this.router.url.includes('/quartz')){
			this.title.setTitle('Find Best Quality Quartz Products for Your Customized Needs | Cosmos Quartz')
		}
		else if(this.router.url.includes('/privacy-policy')){
			this.title.setTitle('Privacy Policy | Cosmos Quartz')
		}else if(this.router.url.includes('/disclaimer')){
			this.title.setTitle('Disclaimer | Cosmos Quartz')
		}else if(this.router.url.includes('/faq')){
			this.title.setTitle('Frequently Asked Questions About Quartz Products | Cosmos Quartz')
		}else if(this.router.url.includes('/book-appointment')){
			this.title.setTitle('Book an Appointment Online for Warehouse Visit | Cosmos Quartz')
		}else if(this.router.url.includes('/projects')){
			this.title.setTitle('High-Quality Quartz Products for Different Projects | Cosmos Quartz')
		}else{
			this.title.setTitle('Home')
		}
		
	}
	ngOnInit() {
		// this.getMessage()
		// setTimeout(() => {
			
		// }, 400);
		this.ngAfterViewInit()
	}
	ngOnDestroy() {
		// unsubscribe to ensure no memory leaks
		// this.subscription.unsubscribe();
	}
	ngAfterViewInit() {
		setTimeout(() => {
			this.getHeader = true
			
			window.onscroll = function () { myFunction() };
			$(document).ready(function () {
				
				let pathname = window.location.pathname;
				var header:any = document.getElementById("igm-body");
				// console.log(header);
				
				if (pathname.indexOf("/quartz/") != -1) {
					header.classList.add("noSticky");
					$('#headeDesign').removeClass('sticky')
				} else {
					header.classList.remove("noSticky");
					$('#headeDesign').removeClass('sticky')
	
				}
	
	
			});
			var header:any = document.getElementById("headeDesign");
				if(header){
					var sticky: any = header.offsetTop;
				}
	
			function myFunction() {
					if(header){

						if (window.pageYOffset > sticky) {
							// let x = header.classList.findIndex(f => f == 'sticky' )
							header.classList.add("sticky");
							$('.slider-heading-product').addClass('stick');
							$('.filterbtn').removeClass('filterSearch');
						} else {
							header.classList.remove("sticky");
							$('.slider-heading-product').removeClass('stick');
							$('.filterbtn').addClass('filterSearch');
						}
					}
			}
		}, 800);
	}
}