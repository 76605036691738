export const environment = {
  production: true
};
export interface Environment 
{
	endPoint:string;
	imageLink:string;
	websiteLink:string;

}

export const PROD: Environment = {
	endPoint:'https://surfaces.cosmosgranite.com/api/',
	// endPoint:'https://staging.cosmosgranite.com/api/',
	imageLink:'https://staging.cosmosgranite.com/',
	websiteLink: 'https://staging.quartz.cosmosgranite.com'
}

export const environment1: Environment= PROD;
