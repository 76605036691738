<header class="headeDesign justify-content-between justify-content-lg-start" id="headeDesign">
	<a class="navbar-brand p-0 fixed-logo" routerLink="/">
		<img src="{{tinyUrl}}/logo.png" class="img-fluid" width="120">
	</a>
	<nav class="p-0 navbar navbar-expand-lg navbar-dark">
		<div class="container-fluid pl-0">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="navbar-collapse collapse" id="collapsingNavbar">
				<ul class="nav navbar-nav ml-auto">
					<li class="dropdown m-dd open">
						<a (click)="mTogle()" routerLink="/quartz" style="cursor: pointer;"
							class="py-3 nav-link px-1 px-lg-2 text-dark arrow-toggle">Quartz<span
								class="caret"></span></a>
						<button class="m-tgle btn btn-link px-4"><i class="fa fa-caret-down text-dark"
								aria-hidden="true"></i></button>
						<ul class="dd-lrg dropdown-menu animated fadeInDown" role="menu">
							<li>
								<div class="d-flex flex-wrap flex-lg-nowrap">
									<div class="min-col col-md-6 col-sm-6">
										<h6 class="title">Color</h6>
										<div class="col-md-12 card mb-2 px-0">
											<a (click)="mTogle()" routerLink="/quartz"
												class="py-0 col-md-12 d-flex align-items-center">
												<div class="cf1 border-right" style="width: 25px!important;"
													[ngStyle]="{'background-color': 'white'}">
												</div>
												<span class="text-dark">ALL</span>
											</a>
										</div>
										<div class="row mx-0" *ngFor="let list of colorArray">
											<div class="col-md-12 card mb-2 px-0">
												<div class="row no-gutters h-100" (click)="getColor(list)"
													style="cursor: pointer;">
													<a (click)="mTogle()"
														class="py-0 col-md-12 d-flex align-items-center">
														<div class="cf1 border-right"
															[ngStyle]="{'background-color': list.color}">
														</div>
														<span class="text-dark">{{list.color}}</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</li>
					<li class="dropdown m-dd" [routerLink]="['/projects']">
						<a class="py-3 nav-link px-1 px-lg-2 text-dark dropdown-toggle" [routerLink]="['/projects']"
							(click)="mTogle()" role="button" aria-expanded="false">Projects<span
								class="caret"></span></a>
						<button class="m-tgle btn btn-link px-4"><i class="fa fa-caret-down text-dark"
								aria-hidden="true"></i></button>
						<ul class="dd-lrg dropdown-menu animated fadeInDown" role="menu">
							<li>
								<div class="d-flex flex-wrap flex-lg-nowrap">
									<div class="min-col col-md-6 col-sm-6">
										<h6 class="title">Residential</h6>
										<div class="row mx-0">
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Kitchen'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/slider/1.jpg">
														</div>
														Kitchen
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Bathroom'" (click)="mTogle()">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/slider/2.jpg">
														</div>
														Bathroom
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Living-Space'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/1.png">
														</div>
														Living Space
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Bedroom'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/2.png">
														</div>
														Bedroom
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Laundry-Room'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/1.png">
														</div>
														Laundry Room
													</div>
												</div>
											</a>
										</div>
									</div>
									<div class="min-col col-md-6 col-sm-6">
										<h6 class="title">Commercial</h6>
										<div class="row mx-0">
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Reception'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/1.png">
														</div>
														Reception
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Lobby'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/2.png">
														</div>
														Lobby
													</div>
												</div>
											</a>
											<a class="col-md-12 card mb-2 px-0" [routerLink]="['/projects']"
												[fragment]="'Waiting-Area'">
												<div class="row no-gutters">
													<div class="text-dark col-md-12 d-flex align-items-center">
														<div class="cf1">
															<img src="{{tinyUrl}}/newColor/1.png">
														</div>
														Waiting Area
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</li>
					<li class="dropdown m-dd">
						<a class="py-3 nav-link px-1 px-lg-2 text-dark arrow-toggle" style="cursor: pointer;"
							routerLink="/customer-service">Customer Service <span class="caret"></span></a>
						<button class="m-tgle btn btn-link px-4"><i class="fa fa-caret-down text-dark"
								aria-hidden="true"></i></button>
						<ul class="dd-lrg dropdown-menu animated fadeInDown" role="menu">
							<li>
								<div class="col-12 px-0 d-flex flex-wrap flex-lg-nowrap">
									<div class="min-col col-md-6 col-sm-6 mb-2 px-2">
										<a class="text-dark card py-2 align-items-center text-center border"
											(click)="mTogle()" routerLink="/location">
											<div class="mb-1 text-center">
												<i class="fa fa-map-marker fa-2x"></i>
											</div>
											Location / Where to Buy
										</a>
									</div>
									<div class="min-col col-md-6 col-sm-6 mb-2 px-2">
										<a class="text-dark card py-2 align-items-center text-center border"
											(click)="mTogle()" routerLink="/contact-us">
											<div class="mb-1 text-center">
												<i class="fa fa-phone fa-2x"></i>
											</div>
											Contact
										</a>
									</div>
								</div>
								<div class="col-12 px-0 d-flex flex-wrap flex-lg-nowrap">
									<div class="min-col col-md-6 col-sm-6 mb-2 px-2">
										<a class="text-dark card py-2 align-items-center text-center border"
											(click)="mTogle()" routerLink="/customer-service">
											<div class="mb-1 text-center">
												<i class="fa fa-headphones fa-2x"></i>
											</div>
											Care and Warranty
										</a>
									</div>
									<div class="min-col col-md-6 col-sm-6 mb-2 px-2">
										<a class="text-dark card py-2 align-items-center text-center border"
											routerLink="/faq" (click)="mTogle()">
											<div class="mb-1 text-center">
												<i class="fa fa-question fa-2x"></i>
											</div>
											FAQ
										</a>
									</div>
								</div>
								<div class="min-col col-md-12 col-sm-12 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center text-center border"
										routerLink="/book-appointment" (click)="mTogle()">
										<div class="mb-1 text-center">
											<i class="fa fa-newspaper-o fa-2x"></i>
										</div>
										Book Appointment
									</a>
								</div>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<a class="py-3 nav-link px-1 px-lg-2 text-black" target="_blank"
							href="https://www.cosmossurfaces.com">Cosmos Main </a>
						<!-- dd-lrg.// -->
					</li>
				</ul>
			</div>
			<ul class="ml-auto scl-net nav">
				<li><a class="text-dark d-flex align-items-center" href="https://www.facebook.com/CosmosGraniteUS"
						target="_blank"><i class="fa fa-facebook"></i></a></li>
				<li><a class="text-dark d-flex align-items-center" href="https://twitter.com/CosmosGranite"
						target="_blank"><i class="fa fa-twitter"></i></a></li>
				<li><a class="text-dark d-flex align-items-center" href="https://www.pinterest.com/CosmosGranite"
						target="_blank"><i class="fa fa-pinterest-p"></i></a></li>
				<li class="apple"><a class="d-flex align-items-center"
						href="https://apps.apple.com/ca/app/cosmos-granite-marble/id1227682515" target="_black"><i
							class="fa fa-apple"></i></a></li>
				<li class="android"><a class="d-flex align-items-center"
						href="https://play.google.com/store/apps/details?id=cosmos.granite.marble" target="_black"><i
							class="fa fa-android"></i></a></li>
			</ul>
			<ul class="searchUl">
				<li>
					<div class="hsearchbar">
						<div class="finder__inner">
							<ng-autocomplete #ngauto [data]="autoSuggestionArray" [searchKeyword]="'ProductName'" class="finder__input"
								(selected)='selectEvent($event, ngauto)' (inputChanged)='onChangeSearch($event)' debounceTime="400"
								[itemTemplate]="itemTemplate" placeholder="Enter the Product Name" [(ngModel)] = "productName1"
								[notFoundTemplate]="notFoundTemplate"  id="nnnnnnnnn">
							</ng-autocomplete>
							<!-- <ng-template #itemTemplate let-item >
								<a [innerHTML]="item?.ProductName" id="abcd"></a>
							</ng-template> -->
							<ng-template #itemTemplate let-item>
								<a [innerHTML]="item?.ProductName" id="abcd"></a>
								</ng-template>
								
								<ng-template #notFoundTemplate let-notFound>
								<div [innerHTML]="pNotFound"></div>
								</ng-template>
							<!-- <div class="finder__icon" ref="icon"></div> -->
							<a  class="shadow search_icon" (click) = "gotoSearch()"><i class="fa fa-search"></i></a>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</nav>
</header>