import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'index', pathMatch: 'full' },
	{ path: '', loadChildren: () => import('./index/index.module').then(m => m.IndexModule) },
	{ path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
	{ path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
	{ path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
	{ path: 'disclaimer', loadChildren: () => import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },
	{ path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
	{ path: 'customer-service', loadChildren: () => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
	{ path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule) },

	{ path: 'product-details', loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule) },
	{ path: 'quartz/:id', loadChildren: () => import('./product-details1/product-details1.module').then(m => m.ProductDetails1Module) },

	{ path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
	{ path: 'quartz', loadChildren: () => import('./list/list.module').then(m => m.ListModule) },
	{ path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
	{ path: 'projects', loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) },
	{ path: 'book-appointment', loadChildren: () => import('./book-appointment/book-appointment.module').then(m => m.BookAppointmentModule) },
	{ path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)},
	{ path: 'warranty', loadChildren: () => import('./warranty/warranty.module').then(m => m.WarrantyModule) },

	{ path: '**', redirectTo: 'not-found' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
