import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { UserService } from '../../services/user.service';
declare let $: any;
declare let jQuery: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	tinyUrl = 'https://cosmosgranite.nyc3.digitaloceanspaces.com/cosmossurfaces/images';
	autoSuggestionArray = []
	itemTemplate = ''
	productName1
	pNotFound = 'No Product found'
	constructor(private UserService: UserService, private router: Router) {

	}
	colorArray = []
	ngOnInit(): void {
		window.onscroll = function () { myFunction() };

		var header:any = document.getElementById("headeDesign");

		if (header) {
			var sticky = header.offsetTop;

		}


		function myFunction() {
			if (window.pageYOffset > sticky) {
				header.classList.add("sticky"); 
			} else {
				header.classList.remove("sticky");
			}
		}
		this.getList()
		if ($(window).width() >= 992) {
			$('.headeDesign .dropdown').click(function () {
				let ele = $(this).find(".dd-lrg")
				$(this).find(".dd-lrg").css('display', 'none');

			});
			$('.headeDesign .dropdown').mouseleave(function () {
				$(this).find(".dd-lrg").css('display', 'none');

			});
			$('.headeDesign .dropdown').mouseover(function () {
				$(this).find(".dd-lrg").css('display', 'block');
			});
		} else if ($(window).width() <= 991) {
			$('.headeDesign li a').click(function () {
				$(".navbar-collapse").collapse('hide');
				$(".dd-lrg").hide('add');
				$(this).find(".dropdown-menu").css('display', 'none');
			});
			$('.m-tgle').click(function () {
				$(".dd-lrg").removeClass('add');
				$(this).parent(".m-dd").find(".dd-lrg").toggle('add');
			});
		}

	}

	mTogle() {
		if ($(window).width() <= 991) {
			$(".navbar-collapse").collapse('hide');
			$(".dd-lrg").hide('add');
			$(this).find(".dropdown-menu").css('display', 'none');
		}
		else { }
	}
	getList() {
		this.UserService.getAllFilter().subscribe(data => {
			// console.log(data);
			// var myArray:any
			// myArray =  data.product.filter((v,i,a)=>a.findIndex(t=>(t.ProductColor === v.ProductColor))===i)
			// // console.log(myArray);

			// this.colorArray = myArray.filter((a) =>!a.ProductColor.startsWith("#"));
			// let objIndex  = this.colorArray.findIndex((obj => obj.ProductColor == 'Cream'));
			// this.colorArray[objIndex].ProductColorName = "#FFFDD0"
			this.colorArray = data.filter.color

			// console.log(this.colorArray); 


		})
	}
	getColor(vlr) {
		localStorage.setItem('getColor', JSON.stringify(vlr))
		// this.router.navigate(['/quartz'])
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate(['/quartz/']));
	}
	onChangeSearch(val: string) {

		if (val) {
			this.autoSuggestionArray = []
			var isNew = 0
			this.UserService.autoSuggestspecialProducts(val, isNew).subscribe(data => {
				if (data.productsuggestion) {
					this.autoSuggestionArray = data.productsuggestion
				}
				return
			})
		}
	}
	selectEvent(data, ngauto) {
		this.productName1 = ""
		this.itemTemplate = ""

		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate(['/quartz/', data.productSlug]));
		ngauto.clear();
	}
	gotoSearch() {
		// console.log(this.productName1);

		var searching: any
		if (this.productName1) {
			this.UserService.autoSuggestspecialProducts(this.productName1, 0).subscribe(data => {
				searching = data.productsuggestion.filter(x => x.ProductName.toLowerCase() == this.productName1.toLowerCase())
				// console.log(searching)
				this.productName1 = ""
				$('.ng-valid').val('')
				return
			})
		}
	}

}
