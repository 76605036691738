import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { environment1 } from '../../environments/environment.prod';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {  Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private subject = new Subject<any>();
	addParam = 'cosmos'
	constructor(private httpClient: HttpClient) { }
	httpOptions = {
		headers: new HttpHeaders({
			// 'Content-Type': 'application/json'
		})
	}

	
	
	sendProductId(id:any) {
        this.subject.next(id);
	}
	getProductId(): Observable<any> {
        return this.subject.asObservable();
	}
	specialProducts(page): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "specialproductlistquartz?page=" + page+'&website=' + this.addParam)
			.pipe(retry(1))
	}
	autoSuggestspecialProducts(name, isnew): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "getautosuggestionspecialproductlistquartz?name=" + name)
			.pipe(retry(1))
	}
	specialproductdetailquartz(id): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "specialproductdetailquartz?slug=" + id+'&website=' + this.addParam)
			.pipe(retry(1))
	}
	colorListing(): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "specialProductColorListQuartz")
			.pipe(retry(1))
	}

	selectColor(data): Observable<any> {
		return this.httpClient.post<any>(environment1.endPoint + 'getColorListQuartz', JSON.stringify(data), this.httpOptions)
			.pipe(retry(1))
	}
	allLocationDetails(): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "allLocationDetails")
			.pipe(retry(1))
	}
	allLocation(): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "alllocation")
			.pipe(retry(1))
	}

	filterProduct(data): Observable<any> {
		return this.httpClient.post<any>(environment1.endPoint + 'getSpecialProductFilter', data, this.httpOptions)
			.pipe(retry(1))
	}

	specialproductlistquartz(): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + 'specialproductlistquartz'+'?page=6'+'&website=' + this.addParam)
			.pipe(retry(1))
	}
	getAllFilter(): Observable<any> {
		return this.httpClient.get<any>(environment1.endPoint + "getAllFilter"+'?website=' + this.addParam)
			.pipe(retry(1))
	}

	getintouch(data): Observable<any> {
		return this.httpClient.post<any>(environment1.endPoint + 'getintouch', data, this.httpOptions)
			.pipe(retry(1))
	}
	ordersample(data): Observable<any> {
		return this.httpClient.post<any>(environment1.endPoint + 'ordersample', data, this.httpOptions)
			.pipe(retry(1))
	}
	bookAppointment(data): Observable<any> {
		return this.httpClient.post<any>(environment1.endPoint + 'appointment', data, this.httpOptions)
			.pipe(retry(1))
	}
	// addHero(hero): Observable<any> {
	// 	return this.httpClient.post<any>(environment1.endPoint, hero, this.httpOptions)
	// 	  .pipe(
	// 		catchError(this.handleError('addHero', hero))
	// 	  );
	//   }

	//https://staging.cosmosgranite.com/api/allLocationDetails
	//https://staging.cosmosgranite.com/api/specialproductlistquartz
	//https://staging.cosmosgranite.com/api/specialproductlistquartz?page=6&website=igm
	//https://staging.cosmosgranite.com/api/ordersample
}
