<footer class="pt-4 section f-classic context-dark">
    <div class="container-fluid px-lg-5 px-3">
       <div class="row row-30">
          <div class="col-md-3 col-xl-3">
             <div class="pr-xl-4">
                <a class="brand" routerLink="/"><img class="brand-logo-light" src="{{tinyUrl}}/logo.png" height="150"></a>
                <!-- Rights-->
             </div>
          </div>
          <div class="col-md-5 col-xl-5">
             <p class="my-3">A perfect blend of polymeric technology combined with the beauty and durability of Natural Quartz.</p>
             <div class="col-md-12 d-flex flex-wrap px-0">
              <picture>
                <source media="(max-width: 767px)" srcset="{{tinyUrl2}}/partner/m-multi-partner.png"> 
                  <img alt="partner" class="img-fluid" width="420" src="{{tinyUrl2}}/partner/multi-partner.png">
                </picture>
           </div>
           <hr>
             <h5>Follow Us</h5>
             <div class="row">
                <div class="mb-3 mb-md-0 col-xl-12">
                   <ul class="scl-net nav">
                      <li><a class="waves-effect waves-dark" href="https://www.facebook.com/CosmosGraniteUS" target="_blank"><i class="fa fa-facebook"></i></a></li>
                      <li><a class="waves-effect waves-dark" href="https://twitter.com/CosmosGranite" target="_blank"><i class="fa fa-twitter"></i></a></li>
                      <li><a class="waves-effect waves-dark" href="https://www.pinterest.com/CosmosGranite" target="_blank"><i class="fa fa-pinterest-p"></i></a></li>
                   </ul>
                </div>
             </div>
          </div>
          <div class="col-md-3 col-xl-3 d-flex flex-wrap justify-content-sm-center">
             <div>
                <h5>Helpful Links</h5>
                <ul class="nav-list">
                   <li><a routerLink="/">Home</a></li>
                   <!-- <li><a routerLink="/about-us">About Us</a></li> -->
                   <li><a routerLink="/contact-us">Contact Us</a></li>
                   <li><a href="https://www.cosmossurfaces.com/warranty-registration/" target="_blank"> Quartz Warranty </a></li>
                   <!-- <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                   <li><a routerLink="/disclaimer">Disclaimer </a></li> -->
				   <li><a href="https://www.cosmossurfaces.com" target="_blank">Cosmos Main </a></li>
                </ul>
             </div>
          </div>
       </div>
       <div class="row justify-content-sm-center px-2 px-sm-0">
          <p class="mb-1 px-1 px-sm-0"><span>&copy;&nbsp; </span><span class="copyright-year">{{date}}</span><span>&nbsp;</span><span>Cosmosgranite</span><span>.&nbsp;</span><span>All Rights Reserved.</span></p>
       </div>
    </div>
 </footer>